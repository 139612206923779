import request from '@/utils/request'
const preFixUrl ='/user'
 //process.env.NODE_ENV === 'development' ? "/wnnApi/processorUser" : '/fft/processorUser'


/**
 * 系统 -- 用户登录
 * @param {*} data
 * @returns
 */
export const adminLogin = data => {
  return request({
    url: `${preFixUrl}/User/verificationLogin`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}