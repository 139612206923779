<template>
  <div class="login">
    <!-- 浏览器兼容提示 -->
    <div class="tip" :class="[lessIE9 ? 'active' : '']">
      <div class="warn">温馨提示：您当前的浏览器版本过低，请使用其他浏览器</div>
    </div>
    <!--登录内容-->
    <div class="container">
      <div class="login-form">
        <h2>生活服务后台管理系统</h2>
        <div class="form-container">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="60px"
            label-position="top"
            :hide-required-asterisk="true"
            class="demo-ruleForm"
          >
            <el-form-item label="账号" prop="accountNumber">
              <el-input
                prefix-icon="el-icon-user"
                v-model="ruleForm.accountNumber"
                placeholder="请输入账号"
                @input="inputChange"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input
                prefix-icon="el-icon-lock"
                v-model="ruleForm.password"
                placeholder="请输入密码"
                @input="inputChange"
                clearable
                show-password
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="验证码" prop="checkCode">
              <el-input
                prefix-icon="el-icon-key"
                v-model="ruleForm.checkCode"
                placeholder="请输入验证码"
                maxlength="5"
                @keydown.enter.native="submitForm('ruleForm')"
                @input="inputChange"
              >
                <el-image
                  slot="suffix"
                  style="
                    width: 110px;
                    height: 38px;
                    cursor: pointer;
                    margin-top: 1px;
                  "
                  :src="url"
                  @click.native="getCaptcha()"
                >
                  <div slot="placeholder">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </el-input>
            </el-form-item> -->

            <el-form-item>
              <el-button
                type="primary"
                style="margin-top: 50px"
                :loading="loading"
                :disabled="disabled"
                @click="submitForm('ruleForm')"
                >登 录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <p class="Mt20">
          <!-- <a
            class="text"
            target="_blank"
            href="#"
            >饭饭团</a
          > -->
        </p>
      </div>
    </div>
    <!-- icp编号 -->
    <div class="icp">
      <!-- <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备17025324号-6</a
      > -->
      <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备2023037149号-1</a
      >
    </div>
  </div>
</template>
<script>
/**md5**/
import md5 from '@/assets/js/md5'
import * as systemApi from '@/api/system'
export default {
  name: 'login',
  data() {
    return {
      loading: false,
      disabled: true,
      url: '',
      ruleForm: {
        accountNumber: '',
        password: '',
      },
      rules: {
        accountNumber: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        checkCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      captcha: '',
      identifyCode: '',
      lessIE9: false,
    }
  },
  created() {
    this.loading = false
    if (this.ruleForm.accountNumber && this.ruleForm.password) {
      this.disabled = false
    } else {
      this.disabled = true
    }
    // this.getCaptcha()
    sessionStorage.clear()
  },
  mounted() {
    if (window.ActiveXObject != undefined) {
      this.lessIE9 = false
    } else {
      this.lessIE9 = true
    }
    //重置表单
    this.$refs['ruleForm'].resetFields()
  },
  methods: {
    inputChange() {
      if (this.ruleForm.accountNumber && this.ruleForm.password) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login()
        } else {
          return false
        }
      })
    },
    //获取图片验证码
    getCaptcha() {
      this.uuid = this.$base.guid()
      this.url = this.$base.getPath() + `/nhpay/captcha.jpg?uuid=${this.uuid}`
    },
    //登录
    async login() {
      // this.$router.push("/");
      if (this.loading) {
        return
      }
      let data = JSON.parse(JSON.stringify(this.ruleForm))
      // let passnum="Y" +data.password +data.accountNumber  + "Z"
      //  data.password = md5(passnum).toUpperCase()
      // //  data.uuid = this.uuid
      data.source = 'U'
      this.loading = true
      const params = {
        ...data,
      }

      // let res = await this.$base.postAjax(
      //   "/mallUser/Login/verificationLogin",
      //   params
      // );
      let res = await systemApi.adminLogin(params)
      console.log(res, 'res====>')
      if (res.code != 200) {
        this.loading = false
        this.$message.warning(res.msg)
        // this.getCaptcha()
      } else {
        sessionStorage.setItem('login', JSON.stringify(res.data))
        sessionStorage.setItem('Authorization', res.data.token)
        this.$message.success(res.msg)
        this.$router.push('/customer-management')
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.login {
  position: relative;
  height: 100%;
  min-height: 650px;
  min-width: 1180px;
  text-align: center;
  font-size: 14px;
  font-family: 'Lucida Calligraphy', cursive, serif, sans-serif;
  background-image: url('../../assets/images/bg.jpg');
  background-repeat: no-repeat;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -325px;
    margin-left: -590px;
    width: 1180px;
    height: 650px;
    .login-form {
      position: absolute;
      z-index: 3;
      left: 100px;
      top: 53px;
      width: 510px;
      height: 544px;
      border-radius: 10px;
      padding: 50px 80px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(24px);
      h2 {
        font-size: 30px;
        letter-spacing: 1px;
      }
      .form-container {
        margin-top: 10px;
        text-align: left;
        .el-button {
          width: 100%;
        }
      }
    }
  }
}

.tip {
  position: relative;
  z-index: 3;
  height: 100%;
  width: 100%;
  display: block;
  background: #fff;
}
.active {
  display: none !important;
}
.tip .warn {
  margin: 100px auto;
  height: 100px;
  width: 900px;
  font-size: 24px;
  text-align: center;
  color: #fff;
  line-height: 80px;
}
.icp {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.icp a {
  color: #f4f4f4;
}
.Mt20 {
  margin-top: 50px;
}
</style>
